const FooterData = [
    {
        title: "Restaurant",
        submenu: [
            {
                title: "About Us",
                link: "/about-us"
            },
            {
                title: "Full Menu",
                link: "/menu"
            },
        
            {
                title: "Contact us",
                link: "/contact-us"
            },
        ]
    },
    {
        title: "Usefull Links",
        submenu: [
            {
                title: "Privacy Policy",
                link: "/Privacy"
            },
         
            {
                title: "Imprint",
                link: "/Imprint"
            },
            {
                title: "FAQs",
                link: "#"
            },
            
        ]
    },
]

export default FooterData