const HeaderData = [
    {
        title: 'Home',
        link: '/'
    },
    {
        title: 'About Us',
        link:'/about-us'
       
    },
    {
        title: 'Menu',
        link:'/menu'
       
    },
    {
        title: 'Contact Us',
        link:'/contact-us'
       
    },
  
]

export default HeaderData